import React from 'react'
import Container from '../Container/Container'
import PageDefaultContainer from '../PageDefaultContainer/PageDefaultContainer' 

import styles from './SectionPrograma.module.scss'

function SectionPrograma({content}) {

	return (
		<section className={styles.section}>
			<Container className={styles.container}>
				<PageDefaultContainer content={content}/>
			</Container>
		</section>
	)
}

export default SectionPrograma
