import React from 'react'

import styles from './Container.module.scss'

function Container({children, minHeight = false, className = ''}) {
  return (
    <div className={styles.container + ' ' + className + ' ' + (minHeight ? (styles.minHeight) : '')}>
      {children}
    </div>
  )
}

export default Container