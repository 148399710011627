import React from 'react'
import Image from '../Image/Image'
import Container from '../Container/Container'
import SectionHeading from '../SectionHeading/SectionHeading'
import { Link } from 'react-router-dom'

import styles from './SocialistasHaciendoSection.module.scss'

function SocialistasHaciendoSection({ socialistasHaciendo }) {
	return (
		<section className={styles.socialistasHaciendoSection}>
			<Container className={styles.socialistasHaciendoContainer}>
				<SectionHeading>SOCIALISTAS HACIENDO</SectionHeading>
				<div className={styles.socialistasHaciendoRow}>
					{socialistasHaciendo.map((data) => {
						const link = '/categoria/' + data?.link_categoria || '#'
						return (
							<Link to={link} className={styles.cardLogos} key={data.id}>
								<picture className={styles.imglogo}>
									<Image src={data.src} alt='' />
								</picture>
							</Link>
						)
					})}
				</div>
			</Container>
		</section>
	)
}

export default SocialistasHaciendoSection
