import React from 'react'
import Loading from '../../components/Loading/Loading'
import SectionFiles from '../../components/SectionFiles/SectionFiles'


import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout'
import fetcher from '../../lib/fetcher'

function CampaniasPage() {
	const [files, setFiles] = React.useState([])
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const getData = async (url) => {
			try {
				let files = await fetcher(url)
        let filesData = await Promise.all(files.map((file) => {
          return fetcher(`/media/${file.acf.archivo}`)
        }))

        setLoading(false)
        
        return files.map((file, index) => {
          return {
            ...file,
            file: filesData.find(fileMedia => fileMedia.id === file.acf.archivo) || {}
          }
        })
			} catch (error) {
				console.error(error)
        setLoading(false)
				return []
			}

		}

    async function getPage() {
      let response = await getData('/campanias?per_page=100')
      setFiles(response)
    }

    getPage()

	}, [])

  if (loading) {
    return <Loading />
  }

  return (
    <DefaultLayout>
			<SectionFiles files={files} title="Campañas"/>
    </DefaultLayout>
  )
}

export default CampaniasPage