import React from 'react'
import Container from '../Container/Container'
import PageDefaultContainer from '../PageDefaultContainer/PageDefaultContainer'
import SectionHeading from '../SectionHeading/SectionHeading'

import styles from './SectionCartaOrganica.module.scss'

function SectionCartaOrganica({page}) {

	return (
		<section className={styles.section}>
			<Container className={styles.container}>
				<SectionHeading>Carta Orgánica</SectionHeading>
				<PageDefaultContainer content={page?.content?.rendered || ''} />
			</Container>
		</section>
	)
}

export default SectionCartaOrganica
