import React from 'react'
import Container from '../Container/Container'
import Image from '../Image/Image'
import { Link } from 'react-router-dom'

import styles from './SectionQuienesSomos.module.scss'
import PageDefaultContainer from '../PageDefaultContainer/PageDefaultContainer'

function SectionQuienesSomos({ content }) {
	return (
		<section className={styles.section}>
			<Container className={styles.container}>
				<PageDefaultContainer content={content} />
				<div>
					<div className={styles.socialIcons}>
						<div className={styles.redesIcon}>
							<Link to='/quienes-somos/historia' className={styles.iconLink}>
								<Image
									src={'/assets/img/quienesSomos/1.png'}
									alt=''
									layout='responsive'
									className={styles.iconPicture}
								/>
							</Link>
						</div>
						<div className={styles.redesIcon}>
							<Link to='/quienes-somos/autoridades' className={styles.iconLink}>
								<Image
									src={'/assets/img/quienesSomos/2.png'}
									alt=''
									layout='responsive'
									className={styles.iconPicture}
								/>
							</Link>
						</div>
						<div className={styles.redesIcon}>
							<Link to='/quienes-somos/carta-organica' className={styles.iconLink}>
								<Image
									src={'/assets/img/quienesSomos/3.png'}
									alt=''
									layout='responsive'
									className={styles.iconPicture}
								/>
							</Link>
						</div>
						<div className={styles.redesIcon}>
							<Link to='/quienes-somos/programa' className={styles.iconLink}>
								<Image
									src={'/assets/img/quienesSomos/4.png'}
									alt=''
									layout='responsive'
									className={styles.iconPicture}
								/>
							</Link>
						</div>
						<div className={styles.redesIcon}>
							<Link to='/quienes-somos/estatuto-js' className={styles.iconLink}>
								<Image
									src={'/assets/img/quienesSomos/5.png'}
									alt=''
									layout='responsive'
									className={styles.iconPicture}
								/>
							</Link>
						</div>
					</div>
				</div>
			</Container>
		</section>
	)
}

export default SectionQuienesSomos
