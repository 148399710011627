import React from 'react'
import { useParams } from 'react-router-dom'
import Container from '../Container/Container'
import NewsContainerSimple from '../NewsContainerSimple/NewsContainerSimple'
import NewsSingleWide from '../NewsSingleWide/NewsSingleWide'
import SectionHeading from '../SectionHeading/SectionHeading'

function AllNewsSection({ news, category, search = false }) {
	let { key } = useParams()

	const [firstNews, ...restNews] = news
	return (
		<React.Fragment>
			{search ? (
				<SectionHeading>Resultados para: {key}</SectionHeading>
			) : (
				<SectionHeading>{category ? 'NOTICIAS - ' + category.name : 'NOTICIAS'}</SectionHeading>
			)}
			<Container>
				<NewsSingleWide news={firstNews} />
				<NewsContainerSimple news={restNews} />
			</Container>
		</React.Fragment>
	)
}

export default AllNewsSection
