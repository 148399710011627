import React from 'react'

import styles from './SectionSubHeading.module.scss'

function SectionSubHeading({children, className = ''}) {
  return (
    <h2 className={styles.heading + ' ' + className}>{children}</h2>
  )
}

export default SectionSubHeading