import React from 'react'
import { NavLink } from 'react-router-dom'
import Container from '../Container/Container'
import { BiMenu, BiX, BiSearch } from 'react-icons/bi'

import styles from './Header.module.scss'
import Button from '../Button/Button'
import useNav from '../../hooks/useNav'
import Logo from '../Logo/Logo'
import SectionSearch from '../SectionSearch/SectionSearch'
import { navItems } from './constants'

function Header() {
	const [toggleNav, navVisible] = useNav(false)
	const [searchVisible, setSearchVisible] = React.useState(false)

	React.useEffect(() => {
		document.body.style.overflow = searchVisible ? 'hidden' : 'inherit'
	}, [searchVisible])

	const activeClassName = ({ isActive }) => (isActive ? styles.active : undefined)

	return (
		<header className={styles.header}>
			<Container className={styles.container}>
				<Logo />
				<nav className={styles.navDesktop}>
					{navItems.map((item, index) => (
						<NavLink key={index} to={item.to} className={activeClassName}>
							{item.label}
						</NavLink>
					))}
				</nav>
				<Button
					className={styles.searchButton}
					theme='icon'
					handleClick={() => {
						setSearchVisible((prev) => !prev)
						navVisible && toggleNav()
					}}
				>
					{searchVisible ? <BiX /> : <BiSearch />}
				</Button>
				<Button
					theme='icon'
					className={styles.menu}
					handleClick={() => {
						toggleNav()
						searchVisible && setSearchVisible((prev) => !prev)
					}}
				>
					{navVisible ? (
						<BiX className={styles.menuIcon} />
					) : (
						<BiMenu className={styles.menuIcon} />
					)}
				</Button>
			</Container>
			<nav className={`${styles.nav} ${navVisible ? styles.visible : ''}`}>
				{navItems.map((item, index) => (
					<NavLink key={index} to={item.to} className={activeClassName}>
						{item.label}
					</NavLink>
				))}
			</nav>
			<SectionSearch visible={searchVisible} setSearchVisible={setSearchVisible} />
		</header>
	)
}

export default Header
