import React from 'react'
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading'
import SectionSingleEleccion from '../../components/SectionSingleEleccion/SectionSingleEleccion';
import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout'

import fetcher from '../../lib/fetcher'

function EleccionPage() {
  let { id } = useParams();
	const [page, setPage] = React.useState({})
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const getData = async (url) => {
			try {
				let response = await fetcher(url)
        setLoading(false)
				return response
			} catch (error) {
				console.error(error)
        setLoading(false)
				return []
			}

		}
    async function getPage() {
      let response = await getData('/eleccion-autoridades/' + id)
      setPage(response)
    }

    getPage()

	}, [id])

  if (loading) {
    return <Loading />
  }

  return (
    <DefaultLayout>
			<SectionSingleEleccion page={page} />
    </DefaultLayout>
  )
}

export default EleccionPage