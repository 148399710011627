import React from 'react'
import { useNavigate } from 'react-router-dom'
import sanitize from '../../lib/sanitize'
import Button from '../Button/Button'
import Container from '../Container/Container'

import styles from './SectionSearch.module.scss'
function SectionSearch({ visible = false, setSearchVisible }) {
  const [search, setSearch] = React.useState('')
  const searchInput = React.useRef(null)
  const navigate = useNavigate();
  
  React.useEffect(() => {
    if (visible) {
      searchInput.current.focus()
    }
  }, [visible])

  function handleSearch(e) {
    e.preventDefault()
    setSearch(e.target.value)
  }

  function handleSubmit() {
    if (search !==  '') {
      let key = search

      console.log(key)
      console.log(sanitize(key))
      if (sanitize(key) !== '') {
        setSearchVisible(false)
        setSearch('')
        navigate('/busqueda/' + sanitize(key))
      }
    }
  }
  function handleKeyPress(event) {
    if(event.key === 'Enter'){
      handleSubmit()
    }
  }

  return (
    <section className={`${styles.search} ${visible ? styles.visible : ''}`}>
      <Container>
        <div className={styles.searchContainer}>
          <input type="text" value={search} className={styles.input} placeholder="Buscar..." onChange={handleSearch} onKeyPress={handleKeyPress} ref={searchInput} />
          <Button theme="defaultAlt" handleClick={handleSubmit}>Buscar</Button>
        </div>
      </Container>
    </section>
  )
}

export default SectionSearch