import React from 'react'
import Loading from '../components/Loading/Loading'
import SectionGestiones from '../components/SectionGestiones/SectionGestiones'
import useLegisladores from '../hooks/useLegisladores'

import DefaultLayout from '../layout/DefaultLayout/DefaultLayout'

function GestionesPage() {
  const {legisladores, loading} = useLegisladores()

  if (loading) {
		return <Loading />
	}
  
  return (
    <DefaultLayout>
			<SectionGestiones legisladores={legisladores}/>
    </DefaultLayout>
  )
}

export default GestionesPage