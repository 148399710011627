import Image from '../Image/Image'
import { Link } from 'react-router-dom'
import React from 'react'

function Logo() {
	return (
		<Link to='/' style={{ display: 'inline-block' }}>
			<Image src={'/assets/img/logo.svg'} alt='' width={141} height={57} />
		</Link>
	)
}

export default Logo
