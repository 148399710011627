import React from 'react'
import Loading from '../../components/Loading/Loading'
import SectionEleccionDeAutoridades from '../../components/SectionEleccionDeAutoridades/SectionEleccionDeAutoridades'

import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout'
import fetcher from '../../lib/fetcher'

function EleccionDeAutoridadesPage() {
	const [elecciones, setElecciones] = React.useState([])
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const getData = async (url) => {
			try {
				let data = await fetcher(url)
        setLoading(false)
        return data
			} catch (error) {
				console.error(error)
        setLoading(false)
				return []
			}

		}

    async function getPage() {
      let response = await getData('/eleccion-autoridades?per_page=100')
      setElecciones(response)
    }

    getPage()

	}, [])

  if (loading) {
    return <Loading />
  }

  return (
    <DefaultLayout>
      <SectionEleccionDeAutoridades elecciones={elecciones} />
    </DefaultLayout>
  )
}

export default EleccionDeAutoridadesPage