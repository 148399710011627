import React from 'react'
import Container from '../Container/Container'
import SectionHeading from '../SectionHeading/SectionHeading'
import CardButtonHorizontal from '../CardButtonHorizontal/CardButtonHorizontal'

import styles from './SectionTransparencia.module.scss'

function SectionTransparencia() {
	const sections = [
		{
			key: '1b',
			title: 'BALANCES',
			src: '/assets/img/transparencia/1.png',
			link: '/transparencia/balances',
		},
		{
			key: '2c',
			title: 'CAMPAÑAS',
			src: '/assets/img/transparencia/2.png',
			link: '/transparencia/campanias',
		},
		{
			key: '3e',
			title: 'ELECCIÓN DE AUTORIDADES',
			src: '/assets/img/transparencia/3.png',
			link: '/transparencia/eleccion-de-autoridades',
		},
		{
			key: '4d',
			title: 'RESOLUCIONES',
			src: '/assets/img/transparencia/4.png',
			link: '/transparencia/resoluciones',
		},
	]
	return (
		<section className={styles.section}>
			<Container className={styles.container}>
				<SectionHeading>TRANSPARENCIA</SectionHeading>
				<div className={styles.cardButtonHorizontal}>
					{sections.map((section) => (
						<CardButtonHorizontal
							key={section.key}
							title={section.title}
							src={section.src}
							link={section.link}
						/>
					))}
				</div>
			</Container>
		</section>
	)
}

export default SectionTransparencia
