import React from 'react'
import Loading from '../components/Loading/Loading'
import QueDecimosSection from '../components/QueDecimosSection/QueDecimosSection'
import DefaultLayout from '../layout/DefaultLayout/DefaultLayout'
import fetcher from '../lib/fetcher'

function QueDecimosPage() {
	/*
	let citas = [
		{
			title: 'Estamos convencidos/as que Argentina tiene una oportunidad.”',
			href: 'https://twitter.com/MonicaFein/status/1557491938093400065?s=20&t=glh-3jJvN2R-O0WWPmW2xQ',
			src: '/assets/img/author/monica.jpg',
			author: 'Mónica Fein',
		},
		{
			title:
				'“Millones de Argentinas/os sufren en la pobreza y la incertidumbre persiste. El oficialismo tiene la responsabilidad de actuar con suma madurez y delinear un camino de salida de esta crisis. Toda la coalicion gobernante debe asumirlo así.”',
			href: 'https://twitter.com/MonicaFein/status/1553017017350987776?s=20&t=FTwiaIQkWbkBkOTFoftZnw',
			src: '/assets/img/author/monica.jpg',
			author: 'Mónica Fein',
		},
		{
			title:
				'“Ante los inminentes anuncios del Ministerio de Economía de la Nación respecto a la segmentación de tarifas energética debemos pensar estrategias que protejan a usuarios y consumidores.”',
			href: 'https://twitter.com/EnriqueeEstevez/status/1557538268673179648?s=20&t=FTwiaIQkWbkBkOTFoftZnw',
			src: '/assets/img/author/enrique.jpg',
			author: 'Enrique Estevez',
		},
		{
			title:
				'“Frente a los focos de incendios, exigimos que Cabandié venga a @diputadosar a dar explicaciones. #LeyDeHumedalesYA”',
			href: 'https://twitter.com/EnriqueeEstevez/status/1556711530397310977?s=20&t=FTwiaIQkWbkBkOTFoftZnw',
			src: '/assets/img/author/enrique.jpg',
			author: 'Enrique Estevez',
		},
	]
	*/
	const [loading, setLoading] = React.useState(true)
	const [citas, setCitas] = React.useState([])

	React.useEffect(() => {
		getCitas()
		async function getCitas() {
			setLoading(true)
			try {
				let response = await fetcher('/que-decimos?per_page=5')
				if (Array.isArray(response) && response.length > 0) {
					let autoresUnicos = []
					response.forEach(cita => {
						if (!autoresUnicos.find(autor => autor === cita.acf.autor[0])) {
							autoresUnicos.push(cita.acf.autor[0])
						}
					})
					let autores = await Promise.all(
						autoresUnicos.map((autor) => fetcher(`/socialistas/${autor}`)),
					)
					let autoresImgs = await Promise.all(
						autores.map((autor) => fetcher(`/media/${autor.featured_media}`)),
					)

					let newCitas = response.map((cita) => {
						const autor = autores.find(autor => autor.id === cita.acf.autor[0])
						const autorImg = autoresImgs.find(img => img.id === autor.featured_media)
						return {
							title: '“' + cita.title.rendered + '”',
							href: cita.acf.fuente,
							src: autorImg.media_details.sizes.thumbnail.source_url,
							author: autor.title.rendered,
							key: cita.id
						}
					})
					setCitas(newCitas)
				}
			} catch (error) {
				console.error(error)
			}
			setLoading(false)
		}
	}, [])

	if (loading) {
		return <Loading />
	}
	
	return (
		<DefaultLayout>
			<QueDecimosSection citas={citas} />
		</DefaultLayout>
	)
}

export default QueDecimosPage
