import React from 'react'
import SectionContacto from '../components/SectionContacto/SectionContacto'
import DefaultLayout from '../layout/DefaultLayout/DefaultLayout'

function ContactoPage() {
  return (
    <DefaultLayout>
      <SectionContacto />
    </DefaultLayout>
  )
}

export default ContactoPage