import React from 'react'
import Container from '../Container/Container'

import styles from './Hero.module.scss'

function Hero({ children, img, video }) {
	const generateStyle = () => {
		const heroStyle = {}
		if (img) {
			heroStyle.backgroundImage = `url(${img})`
		}
		return heroStyle
	}
	return (
		<div className={styles.hero}>
			{img ? <div style={generateStyle()} className={styles.heroImg}></div> : null}
			{video ? <video src={video} className={styles.heroVideo} autoPlay={true} muted={true} loop={true}/> : null }
			<Container className={styles.heroContainer}>{children}</Container>
		</div>
	)
}

export default Hero
