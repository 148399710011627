import React from 'react'
import Container from '../Container/Container'
import PageDefaultContainer from '../PageDefaultContainer/PageDefaultContainer'
import SectionHeading from '../SectionHeading/SectionHeading'

import styles from './SectionSingleEleccion.module.scss'

function SectionSingleEleccion({page}) {

	return (
		<section className={styles.section}>
			<Container className={styles.container}>
				<SectionHeading><p dangerouslySetInnerHTML={{ __html: page.title.rendered }}></p></SectionHeading>
				<PageDefaultContainer content={page?.content?.rendered || ''} />
			</Container>
		</section>
	)
}

export default SectionSingleEleccion
