import React from 'react'
import CardLegisladores from '../CardLegisladores/CardLegisladores'
import Container from '../Container/Container'
import SectionHeading from '../SectionHeading/SectionHeading'
import SectionSubHeading from '../SectionSubHeading/SectionSubHeading'

import styles from './SectionAutoridades.module.scss'

function SectionAutoridades({ mesaDirectivaNacional }) {
	return (
		<section className={styles.section}>
			<Container className={styles.container}>
				<SectionHeading>Autoridades</SectionHeading>
				<SectionSubHeading className={styles.subheader}>Mesa directiva nacional</SectionSubHeading>
				<div className={styles.list}>
					{mesaDirectivaNacional.map((legislador) => {
						return (
							<CardLegisladores
								key={legislador.nombre}
								src={legislador.src}
								nombre={legislador.nombre}
								cargo={legislador.cargo}
								email={legislador.email}
								redes={legislador.redes}
							/>
						)
					})}
				</div>
			</Container>
		</section>
	)
}

export default SectionAutoridades
