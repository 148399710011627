import React from 'react'
import Container from '../Container/Container'
import Button from '../Button/Button'
import SectionHeading from '../SectionHeading/SectionHeading'

import styles from './SectionEleccionDeAutoridades.module.scss'

function SectionEleccionDeAutoridades({ elecciones }) {
	return (
		<section className={styles.files}>
			<Container className={styles.container}>
				<SectionHeading back="/transparencia">Elección de autoridades</SectionHeading>
				<div className={styles.listLarge}>
					{elecciones.map((eleccion, index) => {
            const destacado = index === 0;
						return (
							<div key={index} className={destacado ? styles.destacado : styles.file}>
								<h3 className={destacado ? styles.titleWhite : styles.title} dangerouslySetInnerHTML={{ __html: eleccion.title.rendered }} />
								<Button href={"/transparencia/eleccion-de-autoridades/" + eleccion.id +'/'+eleccion.slug} theme={destacado ? 'defaultAlt': 'default'}>Ver más</Button>
							</div>
						)
					})}
				</div>
			</Container>
		</section>
	)
}

export default SectionEleccionDeAutoridades
