
import React from 'react'
import fetcher from '../lib/fetcher'

const useSocialistasHaciendo = (initialState = []) => {
  const [socialistasHaciendo, setSocialistasHaciendo] = React.useState(initialState)
	const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
		;(async function getSocialistasHaciendo() {
			try {
				let response = await fetcher('/socialistas-haciendo')

				const imgs = await Promise.all(
					response.map((post) => {
						return fetcher(`/media/${post.acf.logo}`)
					}),
				)

				response = response.map(data => {
					return {
						link_categoria: data?.acf?.link_categoria || '',
						src: imgs.find((img) => img.id === data?.acf?.logo)?.source_url || '',
						id: data.id
					}
				})
				setSocialistasHaciendo(response)
				setLoading(false)
			} catch (error) {
				console.error(error)
				setLoading(false)
			}
		})()
	}, [])

  return {socialistasHaciendo, loading}
}

export default useSocialistasHaciendo