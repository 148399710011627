import React from 'react'
import fetcher from '../../lib/fetcher'
import Container from '../Container/Container'
import Image from '../Image/Image'

import style from './SitiosDeInteres.module.scss'

function SitiosDeInteres() {
  const [sitiosInteres, setSitiosInteres] = React.useState([])

  React.useEffect(() => {
		async function getSitiosInteres() {
			try {
				let response = await fetcher('/sitios_interes')

				const imgs = await Promise.all(
					response.map((post) => {
						return fetcher(`/media/${post.acf.logo}`)
					}),
				)

				response = response.map(data => {
					return {
						link: data?.acf?.link || '',
						src: imgs.find((img) => img.id === data?.acf?.logo)?.source_url || '',
						id: data.id
					}
				})
				setSitiosInteres(response)
			} catch (error) {
				console.error(error)
			}
		}
    getSitiosInteres()
	}, [])
  return (
    <section className={style.sitiosDeInteres}>
      <Container>
        <div className={style.list}>
        {
          sitiosInteres.map((sitio) => (
            <a href={sitio.link} key={sitio.id} target="_blank" rel="noreferrer" >
              <Image src={sitio.src} alt="" layout="responsive" className={style.sitio}/>
            </a>
          ))
        }
        </div>
      </Container>
    </section>
  )
}

export default SitiosDeInteres