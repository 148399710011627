import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import SingleNewsPage from "../../components/SingleNewsPage/SingleNewsPage";
import DefaultLayout from "../../layout/DefaultLayout/DefaultLayout"
import fetcher from '../../lib/fetcher'
import NotFound from "../NotFound";

function NoticiaSingle() {
  let { id } = useParams();
  const [loading, setLoading] = React.useState(true)
  const [news, setNews] = React.useState({})
  const [img, setImg] = React.useState({})

  React.useEffect(() => {
    async function getNews(){
      setLoading(true)
      try {
        const response = await fetcher('/posts/' + id)
        const img = await fetcher('/media/' + response.featured_media)
        setImg(img)
        setNews(response)
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }
    getNews()
  }, [id])
  
  if (loading) {
    return <Loading />
  }
  if (news.id === undefined) {
    return <NotFound />
  }
  return (
    <DefaultLayout>
      <SingleNewsPage news={news} img={img} />
    </DefaultLayout>
  )
}


export default NoticiaSingle