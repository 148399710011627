import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from './Button.module.scss'

const Button = ({
	children,
	className = '',
	handleClick = () => null,
	theme = 'default',
	href,
	external,
	disabled = false,
}) => {
	function buttonTheme() {
		const useTheme = {
			default: styles.buttonDefault,
			defaultAlt: styles.buttonDefaultAlt,
			outline: styles.buttonDefaultOutline,
			disabled: styles.buttonDisabled,
			none: styles.none,
			icon: styles.buttonIcon,
		}

		return disabled ? useTheme.disabled : useTheme[theme]
	}

	return href ? (
		external ? (
			<a
				className={`${buttonTheme()} ${className}`}
				href={href}
				target='_blank'
				rel='noopener noreferrer'
			>
				{children}
			</a>
		) : (
			<Link to={href} className={`${buttonTheme()} ${className}`}>
				{children !== undefined ? children : ''}
			</Link>
		)
	) : (
		<button className={buttonTheme() + ' ' + className} onClick={handleClick} disabled={disabled}>
			{children !== undefined ? children : ''}
		</button>
	)
}

Button.propTypes = {
	children: PropTypes.node.isRequired,
	handleClick: PropTypes.func,
	theme: PropTypes.string,
	className: PropTypes.string,
	href: PropTypes.string,
}

export default Button
