import React from 'react'
import Loading from '../components/Loading/Loading'
import SocialistasHaciendoSection from '../components/SocialistasHaciendoSection/SocialistasHaciendoSection'
import useSocialistasHaciendo from '../hooks/useSocialistasHaciendo'
import DefaultLayout from '../layout/DefaultLayout/DefaultLayout'

function SocialistasHaciendoPage() {
	const {socialistasHaciendo, loading} = useSocialistasHaciendo()

  if (loading) {
		return <Loading />
	}
  return (
    <DefaultLayout>
			<SocialistasHaciendoSection socialistasHaciendo={socialistasHaciendo} />
    </DefaultLayout>
  )
}

export default SocialistasHaciendoPage