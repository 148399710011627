import { API_URL } from "../constants/config"

export default function fetcher(url, data = undefined, base_url = API_URL) {
  return fetch(`${base_url}${url}`, {
    method: data ? 'POST' : 'GET',
    credentials: 'include',
    body: data,
  }).then((res) => {
    if (res.status > 399 && res.status < 200) {
      throw new Error()
    }
    return res.json()
  })
}