import React from 'react'
import Button from '../Button/Button'
import Container from '../Container/Container'
import SubscripcionNewsletter from '../SubscripcionNewsletter/SubscripcionNewsletter'

import styles from './NewsletterSection.module.scss'

function NewsletterSection() {


	return (
		<section className={styles.newsletterSection}>
			<Container className={styles.newsletterContainer}>
				<SubscripcionNewsletter theme='default'>
					<h4 className={styles.newsletterh4}>Subscribite a nuestro newsletter</h4>
				</SubscripcionNewsletter>
			</Container>
		</section>
	)
}

export default NewsletterSection
