import React from 'react'
import Loading from '../../components/Loading/Loading'
import SectionCartaOrganica from '../../components/SectionCartaOrganica/SectionCartaOrganica'


import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout'
import fetcher from '../../lib/fetcher'

function CartaOrganicaPage() {
	const [page, setPage] = React.useState({})
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const getData = async (url) => {
			try {
				let response = await fetcher(url)
        setLoading(false)
				return response
			} catch (error) {
				console.error(error)
        setLoading(false)
				return []
			}

		}
    async function getPage() {
      let response = await getData('/pages/11867')
      setPage(response)
    }

    getPage()

	}, [])

  if (loading) {
    return <Loading />
  }

  return (
    <DefaultLayout>
			<SectionCartaOrganica page={page}/>
    </DefaultLayout>
  )
}

export default CartaOrganicaPage