export function getDateFormatted(
	date,
	options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	},
) {
	return new Date(date).toLocaleDateString('es-ES', options)
}

export function getDateFromFile(date, options = { month: 'long', year: 'numeric' }) {
	let year = date.substring(0, 4)
	let month = date.substring(4, 6)
	let day = date.substring(6, 8)
	const newDate = new Date(year, month - 1, day)
	function firstLetterUpperCase(str) { 
		return str.charAt(0).toUpperCase() + str.slice(1);
	}
	return firstLetterUpperCase(newDate.toLocaleDateString('es-ES', { month: 'long' })) + ' ' + year
}
