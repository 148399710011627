import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import './styles/globals.scss'
import './styles/common/_components.scss'

// pages
import HomePage from './pages'
import ComoParticiparPage from './pages/como-participar'
import NoticiasPage from './pages/noticias'
import QuienesSomosPage from './pages/quienes-somos'
import GestionesPage from './pages/gestiones'
import TransparenciaPage from './pages/transparencia'
import SocialistasHaciendoPage from './pages/socialistas-haciendo'
import ContactoPage from './pages/contacto'
import QueDecimosPage from './pages/que-decimos'
import NoticiaSingle from './pages/noticias/single'
import CategoriaPage from './pages/categories'
import HistoriaPage from './pages/quienes-somos/historia'
import AutoridadesPage from './pages/quienes-somos/autoridades'
import CartaOrganicaPage from './pages/quienes-somos/carta-organica'
import ProgramaPage from './pages/quienes-somos/programa'
import BalancesPage from './pages/transparencia/balances'
import CampaniasPage from './pages/transparencia/campanias'
import EleccionDeAutoridadesPage from './pages/transparencia/eleccion-de-autoridades'
import EleccionPage from './pages/transparencia/elecciones'
import SearchPage from './pages/search'
import NotFoundPage from './pages/NotFound'
import EstatutoJSPage from './pages/quienes-somos/estatuto-js'
import ResolucionesPage from './pages/transparencia/resoluciones'

const router = createBrowserRouter([
	{
		path: '/',
		element: <HomePage />,
	},
	{
		path: '/como-participar',
		element: <ComoParticiparPage />,
	},
	{
		path: '/noticias',
		element: <NoticiasPage />,
	},
	{
		path: 'noticias/:id/:slug',
		element: <NoticiaSingle />,
	},
	{
		path: 'categoria/:id/:slug',
		element: <CategoriaPage />,
	},
	{
		path: '/quienes-somos',
		element: <QuienesSomosPage />,
	},
	{
		path: '/quienes-somos/historia',
		element: <HistoriaPage />,
	},
	{
		path: '/quienes-somos/autoridades',
		element: <AutoridadesPage />,
	},
	{
		path: '/quienes-somos/carta-organica',
		element: <CartaOrganicaPage />,
	},
	{
		path: '/quienes-somos/programa',
		element: <ProgramaPage />,
	},
	{
		path: '/quienes-somos/estatuto-js',
		element: <EstatutoJSPage />,
	},
	{
		path: '/gestiones',
		element: <GestionesPage />,
	},
	{
		path: '/transparencia',
		element: <TransparenciaPage />,
	},
	{
		path: '/transparencia/balances',
		element: <BalancesPage />,
	},
	{
		path: '/transparencia/campanias',
		element: <CampaniasPage />,
	},
	{
		path: '/transparencia/eleccion-de-autoridades',
		element: <EleccionDeAutoridadesPage />,
	},
	{
		path: '/transparencia/eleccion-de-autoridades/:id/:slug',
		element: <EleccionPage />,
	},
	{
		path: '/transparencia/resoluciones',
		element: <ResolucionesPage />,
	},
	{
		path: '/que-decimos',
		element: <QueDecimosPage />,
	},
	{
		path: '/socialistas-haciendo',
		element: <SocialistasHaciendoPage />,
	},
	{
		path: '/contacto',
		element: <ContactoPage />,
	},
	{
		path: '/busqueda/:key',
		element: <SearchPage />,
	},
	{
		path: '*',
		element: <NotFoundPage />,
	},
])

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>,
)
