import React from 'react'
import Button from '../Button/Button'
import Container from '../Container/Container'
import SectionHeading from '../SectionHeading/SectionHeading'
import SectionSubHeading from '../SectionSubHeading/SectionSubHeading'
import { AiFillInstagram } from 'react-icons/ai'
import { FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa'

import styles from './SectionComoParticipar.module.scss'
import SubscripcionNewsletter from '../SubscripcionNewsletter/SubscripcionNewsletter'
import useContactForm from '../../hooks/useContactForm'
import { facebook, instagram, twitter, youtube } from '../../constants/redes'

function SectionComoParticipar() {
	const [handleChange, handleSubmit, message] = useContactForm({}, 51)

	return (
		<section className={styles.section}>
			<Container className={styles.container}>
				<div className={styles.containerSections}>
					<SectionHeading>NOS PODÉS ENCONTRAR:</SectionHeading>
					<SectionSubHeading>¡Seguinos en nuestras redes!</SectionSubHeading>
					<div className={styles.socialIcons}>
						<a href={instagram} target='_blank' rel='noopener noreferrer'>
							<AiFillInstagram className={styles.redesIcon} />
						</a>
						<a href={twitter} target='_blank' rel='noopener noreferrer'>
							<FaTwitter className={styles.redesIcon} />
						</a>
						<a href={youtube} target='_blank' rel='noopener noreferrer'>
							<FaYoutube className={styles.redesIcon} />
						</a>
						<a href={facebook} target='_blank' rel='noopener noreferrer'>
							<FaFacebookF className={styles.redesIconFb} />
						</a>
					</div>
				</div>
				<div className={styles.containerSections}>
					<SectionHeading>SUMATE AL NEWSLETTER:</SectionHeading>
					<SubscripcionNewsletter theme='alt'>
						<SectionSubHeading>Subscribite y recibí todas las novedades.</SectionSubHeading>
					</SubscripcionNewsletter>
				</div>
			</Container>
			<div className={styles.containerSectionsAfiliate}>
				<Container className={styles.containerSections}>
					<SectionHeading>AFILIATE</SectionHeading>
					<SectionSubHeading>
						Afiliate para juntos lograr la argentina que nos merecemos.
					</SectionSubHeading>
					<form className={styles.form} onSubmit={handleSubmit}>
						<input
							type='email'
							name='email'
							placeholder='Ingresa tu email'
							className={styles.input}
							onChange={handleChange}
							required
						/>
						<input
							type='text'
							name='provincia'
							placeholder='Provincia'
							className={styles.input}
							onChange={handleChange}
							required
						/>
						<input
							type='text'
							name='ciudad'
							placeholder='Ciudad'
							className={styles.input}
							onChange={handleChange}
							required
						/>
						{message ? <p className={styles.successMessage}>{message}</p> : null}
						<Button disabled={message ? true : false}>Afiliate</Button>
					</form>
				</Container>
			</div>
		</section>
	)
}

export default SectionComoParticipar
