import React from 'react'
import Loading from '../../components/Loading/Loading'
import SectionAutoridades from '../../components/SectionAutoridades/SectionAutoridades'

import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout'
import fetcher from '../../lib/fetcher'

function AutoridadesPage() {
	const [mesaDirectivaNacional, setMesaDirectivaNacional] = React.useState([])
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const getData = async (url) => {
			try {
				let response = await fetcher(url + '?per_page=100')

				let imgs = await Promise.all(
					response.map((item) => {
						return fetcher(`/media/${item.acf.imagen}`)
					}),
				)

				let all = response.map((item) => {
					let img = imgs.find((img) => img.id === item.acf.imagen) || {}
					return {
						nombre: item.title.rendered,
						src: img.media_details.sizes.thumbnail.source_url,
						cargo: item.acf.cargo,
						email: item.acf.email,
						redes: Object.keys(item.acf.redes_sociales)
							.filter((key) => item.acf.redes_sociales[key] !== '')
							.map((key) => {
								return {
									name: key,
									href: item.acf.redes_sociales[key],
								}
							}),
					}
				})

				setLoading(false)
				return all
			} catch (error) {
				console.error(error)
				setLoading(false)
				return []
			}
		}
		async function getMesaDirectivaNacional() {
			let response = await getData('/mesa_nacional')
			setMesaDirectivaNacional(response)
		}

		getMesaDirectivaNacional()
	}, [])

	if (loading) {
		return <Loading />
	}

	return (
		<DefaultLayout>
			<SectionAutoridades mesaDirectivaNacional={mesaDirectivaNacional} />
		</DefaultLayout>
	)
}

export default AutoridadesPage
