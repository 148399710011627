import React from 'react'
import Container from '../Container/Container'
import QueDecimosCard from '../QueDecimosCard/QueDecimosCard'
import SectionHeading from '../SectionHeading/SectionHeading'

import styles from './QueDecimosSection.module.scss'

function QueDecimosSection({cita, citas}) {
	return (
		<section className={styles.queDecimosSection}>
			<Container className={styles.queDecimosContainer}>
				<SectionHeading>¿QUÉ DECIMOS?</SectionHeading>
				{
					citas ? 
					citas.map(cita => (<QueDecimosCard key={cita.key} cita={cita} xs="xs"/>)) 
					: <QueDecimosCard cita={cita}/>
				}
			</Container>
		</section>
	)
}

export default QueDecimosSection
