import React from 'react'
import Container from '../Container/Container'

import styles from './SectionResoluciones.module.scss'
import SectionHeading from '../SectionHeading/SectionHeading'
import NewsContainerSimple from '../NewsContainerSimple/NewsContainerSimple'

function SectionResoluciones({ news }) {
	return (
		<section className={styles.files}>
			<Container className={styles.container}>
				<SectionHeading back='/transparencia'>Resoluciones</SectionHeading>
				<NewsContainerSimple news={news} />
			</Container>
		</section>
	)
}

export default SectionResoluciones
