
import React from 'react'
import { DOMAIN_PROD } from '../constants/config'
import fetcher from '../lib/fetcher'

const useContactForm = (initialFormState = {}, formId) => {
  const [form, setForm] = React.useState(initialFormState)
	const [message, setMessage] = React.useState('')

	function handleChange(e) {
		setForm((prev) => {
			return {
				...prev,
				[e.target.name]: e.target.value,
			}
		})
	}

	async function handleSubmit(e) {
		e.preventDefault()
		try {
			let form_data = new FormData()
			for (let key in form) {
				form_data.append(key, form[key])
			}
			const { message } = await fetcher(
				`/contact-form-7/v1/contact-forms/${formId}/feedback`,
				form_data,
				DOMAIN_PROD + '/wp-json',
			)
			setMessage(message)
			setTimeout(() => {
				
			}, 1500);
		} catch (error) {
			console.error(error)
		}
	}

  return [handleChange, handleSubmit, message]
}

export default useContactForm