import React from 'react'
import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout'
import Container from '../Container/Container'
import SectionHeading from '../SectionHeading/SectionHeading'

import { HiOutlineEmojiSad } from 'react-icons/hi'

import styles from './NoResults.module.scss'

function NoResults({ keyWord, isSearch = false, isCategory = false }) {
	return (
		<DefaultLayout>
			<Container minHeight className={styles.container}>
				<HiOutlineEmojiSad className={styles.icon} />
				{isSearch ? (
					<SectionHeading className={styles.h2}>
						No se encontraron resultados para "{keyWord}"
					</SectionHeading>
				) : isCategory ? (
					<SectionHeading className={styles.h2}>
						No se encontraron noticias para esta categoría.
					</SectionHeading>
				) : (
					<SectionHeading className={styles.h2}>La página no existe.</SectionHeading>
				)}
			</Container>
		</DefaultLayout>
	)
}

export default NoResults
