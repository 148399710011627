import React from 'react'
import Container from '../Container/Container'
import SectionHeading from '../SectionHeading/SectionHeading'
import CardButtonHorizontal from '../CardButtonHorizontal/CardButtonHorizontal'
import SectionSubHeading from '../SectionSubHeading/SectionSubHeading'

import styles from './SectionGestiones.module.scss'
import CarouselLegisladores from '../CarouselLegisladores/CarouselLegisladores'

function SectionGestiones({ legisladores }) {
	const sections = [
		{
			key: 'bloque-ps',
			title: 'BLOQUE PS',
			src: '/assets/img/gestiones/1.png',
			link: '/categoria/5/bloque-ps',
		},
		{
			key: 'bloque-federal',
			title: 'PS Federal',
			src: '/assets/img/gestiones/2.png',
			link: '/categoria/6/ps-federal',
		},
		{
			key: 'gestiones-locales',
			title: 'GESTIONES LOCALES',
			src: '/assets/img/gestiones/3.png',
			link: '/categoria/7/gestiones-locales',
		},
	]

	return (
		<section className={styles.section}>
			<Container className={styles.container}>
				<SectionHeading>GESTIONES</SectionHeading>
				<SectionSubHeading>Legisladores</SectionSubHeading>
				<CarouselLegisladores legisladores={legisladores} />
				<SectionSubHeading>Nuestras gestiones</SectionSubHeading>
				<div className={styles.cardButtonHorizontal}>
					{sections.map((section) => (
						<CardButtonHorizontal
							key={section.key}
							title={section.title}
							src={section.src}
							link={section.link}
							pictureClass={styles.picture}
						/>
					))}
				</div>
			</Container>
		</section>
	)
}

export default SectionGestiones
