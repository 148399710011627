import React from 'react'
import Container from '../Container/Container'
import Image from '../Image/Image'
import { getDateFormatted } from '../../lib/formatters'

import styles from './SingleNewsPage.module.scss'
import fetcher from '../../lib/fetcher'
import { Link } from 'react-router-dom'
import NewsContainerSimple from '../NewsContainerSimple/NewsContainerSimple'
import SectionHeading from '../SectionHeading/SectionHeading'

function SingleNewsPage({news, img}) {
  const [categories, setCategories] = React.useState([])
  const [relatedNews, setRelatedNews] = React.useState([])
  const { title, date, content } = news
	const formattedDate = getDateFormatted(date)

	React.useEffect(() => {
		async function getPosts() {
			try {
				let response = await fetcher('/posts?per_page=5')
        response = response.filter(post => post.id !== news.id)
				const imgs = await Promise.all(
					response.map((post) => {
						return fetcher(`/media/${post.featured_media}`)
					}),
				)
				response = response.map((post) => {
					return {
						...post,
						img: imgs.find((img) => img.id === post.featured_media) || {},
					}
				})
				const arrPosts = response.map((post, index) => {
					let { title, slug, date, id, excerpt, img } = post

					return {
						title: title.rendered,
						slug,
						date,
						description: excerpt.rendered,
						src: img?.media_details?.sizes?.medium?.source_url || '',
						alt: '',
						key: id,
						category: 'PS Federal',
						featured: index === 0,
						href: '/noticias/'+id+'/'+slug,
					}
				})
				setRelatedNews(arrPosts)
			} catch (error) {
				console.error(error)
				return []
			}
		}
		async function getCategories() {
      try {
        const response = await Promise.all(
					news.categories.map((id) => {
						return fetcher('/categories/' + id)
					}),
				)
        
        setCategories(response)
      } catch (error) {
        console.error(error)
      }
    }

    getCategories()
		getPosts()

	}, [news])

	return (
    <React.Fragment>
      <Container className={styles.newsContainer}>
        <Image src={img.source_url} alt={img.alt_text} layout='responsive' />
        <header	className={styles.header}>
          <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: title.rendered }} />
          <div className={styles.subTitle}>
            <div className={styles.categorias}>{categories.map(({name, id, slug}) => (<Link key={id} to={`/categoria/${id}/${slug}` }><h4 className={styles.cat}>{name}</h4></Link>))}</div>
            <time dateTime={date}>{formattedDate}</time>
          </div>
        </header>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: content.rendered }}
        />
      </Container>
      <Container className={styles.moreNewsContainer}>
        <SectionHeading>MÁS NOTICIAS</SectionHeading>
        <NewsContainerSimple news={relatedNews.filter((_, index) => index < 4)}/>
      </Container>
    </React.Fragment>
	)
}

export default SingleNewsPage
