import React from 'react'
import Container from '../../components/Container/Container'
import Hero from '../../components/Hero/Hero'
import Loading from '../../components/Loading/Loading'
import SectionPrograma from '../../components/SectionPrograma/SectionPrograma'


import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout'
import fetcher from '../../lib/fetcher'

function ProgramaPage() {
	const [title, setTitle] = React.useState('')
	const [background, setBackground] = React.useState('')
	const [content, setContent] = React.useState('')
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		;(async function getPage() {
			try {
				let response = await fetcher('/pages/11873')
				setTitle(response.acf.titulo)
				setContent(response.content.rendered)
				const img = await fetcher(`/media/${response.featured_media}`)
				setBackground(img.source_url)
				setLoading(false)
			} catch (error) {
				setLoading(false)
			}
		})()
	}, [])

  if (loading) {
    return <Loading />
  }

  return (
    <DefaultLayout>
			<Hero img={background}>
				<Container>
					<h2 className='heroHeadings'>{title}</h2>
				</Container>
			</Hero>
			<SectionPrograma content={content} />
    </DefaultLayout>
  )
}

export default ProgramaPage