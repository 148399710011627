import React from 'react'
import SectionTransparencia from '../../components/SectionTransparencia/SectionTransparencia'
import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout'

function TransparenciaPage() {
  return (
    <DefaultLayout>
			<SectionTransparencia />
    </DefaultLayout>
  )
}

export default TransparenciaPage