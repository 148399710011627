export const navItems = [
	{ to: '/noticias', label: 'Noticias' },
	{ to: '/quienes-somos', label: '¿Quiénes somos?' },
	{ to: '/gestiones', label: 'Bloque PS' },
	{ to: '/transparencia', label: 'Transparencia' },
	{ to: '/contacto', label: 'Contacto' },
	//{ to: '/como-participar', label: '¿Cómo participar?' },
	//{ to: '/que-decimos', label: '¿Qué decimos?' },
	//{ to: '/socialistas-haciendo', label: 'Socialistas Haciendo' },
]
