import Image from '../Image/Image'
import { Link } from 'react-router-dom'
import React from 'react'
import { getDateFormatted } from '../../lib/formatters'

import styles from './NewsSingle.module.scss'

function NewsSingle({ news = {}, featured, onImageLoad = () => null }, ref) {
	const { src, alt = '', title, date, description, href } = news
	const formattedDate = getDateFormatted(date)
	
	return (
		<Link to={href ? href : '#'} className={featured ? styles.featuredCard : styles.card} ref={ref ? ref : null}>
			<Image
				src={src}
				alt={alt}
				layout='responsive'
				onLoad={onImageLoad}
				className={styles.picture}
			/>

			<header>
				<h3 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
				<div className={styles.subTitle}>
					<time dateTime={date}>{formattedDate}</time>
				</div>
			</header>
			<div
				className={styles.description}
				dangerouslySetInnerHTML={{ __html: description }}
			/>
		</Link>
	)
}

export default React.forwardRef(NewsSingle)
