import React from 'react'
import Button from '../Button/Button'
import Container from '../Container/Container'
import SectionHeading from '../SectionHeading/SectionHeading'
import SectionSubHeading from '../SectionSubHeading/SectionSubHeading'
import { AiFillInstagram } from 'react-icons/ai'
import { FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa'

import styles from './SectionContacto.module.scss'
import useContactForm from '../../hooks/useContactForm'
import { facebook, instagram, twitter, youtube } from '../../constants/redes'

function SectionContacto() {
	const [handleChange, handleSubmit, message] = useContactForm({}, 52)
	return (
		<section className={styles.section}>
			<Container className={styles.container}>
				<div className={styles.containerSections}>
					<SectionHeading>CONTACTO</SectionHeading>
					<SectionSubHeading>¡Seguinos en nuestras redes sociales!</SectionSubHeading>
					<div className={styles.socialIcons}>
						<a href={instagram} target='_blank' rel='noopener noreferrer'>
							<AiFillInstagram className={styles.redesIcon} />
						</a>
						<a href={twitter} target='_blank' rel='noopener noreferrer'>
							<FaTwitter className={styles.redesIcon} />
						</a>
						<a href={youtube} target='_blank' rel='noopener noreferrer'>
							<FaYoutube className={styles.redesIcon} />
						</a>
						<a href={facebook} target='_blank' rel='noopener noreferrer'>
							<FaFacebookF className={styles.redesIconFb} />
						</a>
					</div>
				</div>
				<div className={styles.containerSections}>
					<SectionSubHeading>Envianos un mensaje.</SectionSubHeading>
					<form className={styles.form} onSubmit={handleSubmit}>
						<input
							type='text'
							name='your-name'
							placeholder='Nombre y apellido'
							className={styles.input}
							onChange={handleChange}
							required
						/>
						<input
							type='email'
							name='your-email'
							placeholder='Ingresa tu email'
							className={styles.input}
							onChange={handleChange}
							required
						/>
						<textarea
							placeholder='Mensaje'
							name='your-message'
							className={styles.textarea}
							rows='5'
							onChange={handleChange}
						></textarea>
						{message ? <p className={styles.successMessage}>{message}</p> : null}

						<Button disabled={message ? true : false}>Enviar</Button>
					</form>
				</div>
			</Container>
		</section>
	)
}

export default SectionContacto
