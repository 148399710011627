import React from 'react'

import { AiFillInstagram, AiFillFileUnknown, AiOutlineLink } from 'react-icons/ai'
import { FaFacebookF, FaTwitter, FaYoutube, FaLinkedin, FaTiktok } from 'react-icons/fa'

import style from './CardLegisladores.module.scss'

function CardLegisladores({ nombre, cargo, src, email, redes, carousel }) {
	const getRedComponent = (name) => {
		switch (name.toLowerCase()) {
			case 'facebook':
				return <FaFacebookF className={style.redesIconFb} />
			case 'twitter':
				return <FaTwitter className={style.redesIcon} />
			case 'youtube':
				return <FaYoutube className={style.redesIcon} />
			case 'linkedin':
				return <FaLinkedin className={style.redesIconFb} />
			case 'tiktok':
				return <FaTiktok className={style.redesIconFb} />
			case 'instagram':
				return <AiFillInstagram className={style.redesIcon} />
			case 'enlace':
				return <AiOutlineLink className={style.redesIcon} />
			default:
				return <AiFillFileUnknown className={style.redesIcon} />
		}
	}

	return (
		<div className={carousel ? style.cardLegisCarousel : style.cardLegis}>
			<div style={{ backgroundImage: 'url(' + src + ')' }} className={style.picture} />
			<div className={style.info}>
				<h3 className={style.name}>{nombre}</h3>
				<h4 className={style.cargo}>{cargo}</h4>
				<div className={style.line}></div>
				<p className={style.email}>{email}</p>
				<div className={style.redes}>
					{redes.map((red) => {
						return (
							<a
								href={red.href}
								target='_blank'
								rel='noreferrer'
								key={red.url}
								className={style.icon}
							>
								{getRedComponent(red.name)}
							</a>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default CardLegisladores
