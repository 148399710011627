import React from 'react'
import PropTypes from 'prop-types'

import styles from './Image.module.scss'

const Image = ({alt = '', src, className = '', layout, ...otherProps}) => {
  let trueSrc = src ? src : '/assets/img/blank.png'
  return (
    <picture className={styles.imgBox + ' ' + className}>
      {
        layout === 'responsive' ? <img alt={alt} src={trueSrc} {...otherProps} width="100%" /> : <img alt={alt} src={trueSrc} {...otherProps} />
      }
    </picture>
  )
}

Image.prototype = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default Image
