import React from 'react'
import Button from '../Button/Button'
import SectionSubHeading from '../SectionSubHeading/SectionSubHeading'

import styles from './SubscripcionNewsletter.module.scss'

function SubscripcionNewsletter({ children, theme = 'default' }) {
	const [email, setEmail] = React.useState('')
	const [emailSent, setEmailSent] = React.useState(false)
	const [emailError, setEmailError] = React.useState(false)

	const handleSubmit = () => {
		if (email !== '' && emailError === false) {
			setEmailSent(true)
			setTimeout(() => {
				setEmailSent(false)
			}, 1500)
		}
	}

	const handleEmail = (e) => {
		let rawEmail = e.target.value
		function isValidEmail(email) {
			if (email === undefined || email === '') return false
			const emailToTest = email.trim().toLowerCase()
			// eslint-disable-next-line
			const re =
				/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
			return re.test(emailToTest)
		}
		setEmail(rawEmail)
		if (rawEmail !== '') {
			if (isValidEmail(rawEmail)) {
				setEmailError(false)
			} else {
				setEmailError(true)
			}
		} else {
			setEmailError(false)
		}
	}

	React.useEffect(() => {
		if (emailSent) {
			setEmail('')
		}
	}, [emailSent])

	return (
		<React.Fragment>
			{emailSent ? (
				<SectionSubHeading
					className={theme == 'default' ? styles.newsletterh4 : styles.newsletterh4Alt}
				>
					¡Gracias por suscribirte!
				</SectionSubHeading>
			) : (
				<React.Fragment>
					{children}
					<input
						type='email'
						placeholder='Ingresa tu email'
						className={emailError === true ? styles.inputerror : styles.input}
						onChange={handleEmail}
					/>
					<Button theme='defaultAlt' handleClick={handleSubmit}>
						Subscribite
					</Button>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}

export default SubscripcionNewsletter
