import React from 'react'
import Container from '../Container/Container'
import Button from '../Button/Button'

import styles from './SectionFiles.module.scss'
import SectionHeading from '../SectionHeading/SectionHeading'

function SectionFiles({ files, title }) {
	return (
		<section className={styles.files}>
			<Container className={styles.container}>
				<SectionHeading back="/transparencia">{title}</SectionHeading>
				<div className={styles.listLarge}>
					{files.map(({file, title}, index) => {
            const destacado = index === 0;
						return (
							<div key={index} className={destacado ? styles.destacado : styles.file}>
								<h3 className={destacado ? styles.titleWhite : styles.title} dangerouslySetInnerHTML={{ __html: title.rendered }} />
								<Button href={file.source_url} theme={destacado ? 'defaultAlt': 'default'} external>Descargar</Button>
							</div>
						)
					})}
				</div>
			</Container>
		</section>
	)
}

export default SectionFiles
