import React from 'react'
import Container from '../Container/Container'

import styles from './DefaultSectionContainer.module.scss'

function DefaultSectionContainer({ children }) {
	return (
		<section className={styles.section}>
			<Container className={styles.container}>{children}</Container>
		</section>
	)
}

export default DefaultSectionContainer
