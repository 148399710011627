import React from 'react'
import Slider from 'react-slick'
import CardLegisladores from '../CardLegisladores/CardLegisladores'

import styles from './CarouselLegisladores.module.scss'

function CarouselLegisladores({ legisladores }) {
	const settings = {
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		infinite: false,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	}

	return (
		<Slider {...settings} className={styles.carousel}>
			{legisladores.map((legislador) => (
				<CardLegisladores
					key={legislador.nombre}
					src={legislador.src}
					nombre={legislador.nombre}
					cargo={legislador.cargo}
					email={legislador.email}
					redes={legislador.redes}
					carousel
				/>
			))}
		</Slider>
	)
}

export default CarouselLegisladores
