import React from 'react'
import Button from '../Button/Button'
import Logo from '../Logo/Logo'
import { AiFillInstagram } from 'react-icons/ai'
import { FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa'
import styles from './Footer.module.scss'
import Container from '../Container/Container'
import { facebook, instagram, twitter, youtube } from '../../constants/redes'

function Footer() {
	return (
		<footer className={styles.footer}>
			<Container className={styles.container}>
				<div className={styles.leftCol}>
					<Logo />
					<div className={styles.socialIcons}>
						<Button theme='icon' href={instagram} external>
							<AiFillInstagram className={styles.redesIcon} />
						</Button>
						<Button theme='icon' href={twitter} external>
							<FaTwitter className={styles.redesIcon} />
						</Button>
						<Button theme='icon' href={youtube} external>
							<FaYoutube className={styles.redesIcon} />
						</Button>
						<Button theme='icon' href={facebook} external>
							<FaFacebookF className={styles.redesIconFb} />
						</Button>
					</div>
				</div>
				<div className={styles.rightCol}>
					<p>
						Comité Ejecutivo Nacional: Av. Entre Ríos 1018, C1080ABR Ciudad Autónoma de Buenos
						Aires, Argentina.
					</p>
					<p>Dirección de correo electrónico: cen@partidosocialista.org.ar</p>
					<p>
						Teléfono <a href='tel:+5401120533860'>+5401120533860</a>
					</p>
				</div>
			</Container>
		</footer>
	)
}

export default Footer
