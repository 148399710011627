import React from 'react'
import Button from '../Button/Button'
import Container from '../Container/Container'
import NewsSingle from '../NewsSingle/NewsSingle'
import SectionHeading from '../SectionHeading/SectionHeading'

import styles from './NewsContainer.module.scss'

function NewsContainer({ news, category }) {
	const newsLength = news.length
	const [elRefs, setElRefs] = React.useState([])
	const [maxHeight, setMaxHeight] = React.useState('auto')
	const [imageLoaded, setImageLoaded] = React.useState(false)

	React.useEffect(() => {
		setElRefs((elRefs) =>
			Array(newsLength)
				.fill()
				.map((_, i) => elRefs[i] || React.createRef()),
		)
	}, [newsLength])

	React.useEffect(() => {
		if (imageLoaded === true) {
			const maxHeight = elRefs.reduce((current, nextElement) => {
				if (current < nextElement.current.offsetHeight) {
					current = nextElement.current.offsetHeight
				}
				return current
			}, 0)

			setMaxHeight(maxHeight)
		}
	}, [elRefs, imageLoaded])

	return (
		<Container className={styles.newsContainer}>
			{category ? null : <SectionHeading>NOTICIAS</SectionHeading>}
			{category ? <div className={styles.category}>{category}</div> : null}
			<div className={styles.newsRow} style={{ height: maxHeight }}>
				{news.map((singleNews, index) => (
					<NewsSingle
						key={singleNews.key}
						news={singleNews}
						featured={singleNews.featured}
						ref={elRefs[index]}
						onImageLoad={() => setImageLoaded(true)}
					/>
				))}
			</div>
			<Button href='/noticias'>Ver Más</Button>
		</Container>
	)
}

export default NewsContainer
