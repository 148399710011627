import React from 'react'
import Image from '../Image/Image'
import Logo from '../Logo/Logo'

import style from './Loading.module.scss'

function Loading() {
	return (
		<div className={style.loading}>
			<div className={style.skeleton}>
				<svg
					version='1.1'
					id='Capa_1'
					x='0px'
					y='0px'
					viewBox='0 0 595.8 240.4'
				>
					<g>
						<g>
							<g>
								<g>
									<path
										class='st0'
										d='M256.3,120c21.3,0.6,37-5.1,42.3-22.6c0.4,0.6,0.8,1.2,1.2,1.8c3.9,5.4,9.1,9.7,15,14.3
              c1.9,1.4,3.7,2.7,5.1,4.3c1.5,1.6,2.4,3.6,2.4,6.3c0,4.9-4.8,7.4-8.9,7.4c-7.7,0-15-6.3-19.2-12.5v35.1c7.1,3,15,4.6,22.8,4.6
              c21.1,0,35.7-16.7,35.7-37.5c0-8.5-1.9-14.4-5.5-19.5c-3.4-5.1-8.5-9.2-14.9-13.7c-1.6-1.3-3.9-2.8-5.5-4.6
              c-1.6-1.8-3-3.9-3-6.3c0-4.3,4.3-6.8,7.9-6.8c7,0,14.3,6.4,17.9,12.1V48.3c-6.4-3.4-14.3-5.6-21.6-5.6
              c-17.2,0-26.7,10.5-31.1,21.5c-7-14.7-23.4-19.5-43.2-19.5h-27.7c0,37.4,0,74.8,0,112.3h30.4V120z M256.3,68.8l2.2-0.1
              c7.4,0,11.3,6.4,11.3,13.5c0,8.3-5.2,13-13.6,12.5V68.8z'
									/>
								</g>
								<g>
									<path
										class='st0'
										d='M451.4,131.2c27.9,14.2,57.4-5.5,74.3-30.7c-33.4,22.8-85,2.9-88.6-30.4C437,70,406.6,108.5,451.4,131.2z'
									/>
								</g>
								<g>
									<path
										class='st0'
										d='M529.7,91c28.8-71-51.6-105.3-61.2-64.3C460.3,61.8,516.6,36,529.7,91z'
									/>
								</g>
								<g>
									<path
										class='st0'
										d='M490.4,58.6c-2.9-0.6-6-1-9-1.3c-7.2-0.6-14.6-0.8-21.5,1.5c-3.8,1.3-7.7,3.3-10.3,6.4
              c-1.5,1.9-2.1,3.8-2.8,6c0,0-4.5,14.8,16.6,22.5l1.6,0.5c-1.3-0.4-2.7-2.8-3.2-3.9c-0.7-1.6-0.8-3.4-0.6-5.1
              c0.5-3.2,2.9-6.7,5.6-8.5c10.8-7.5,31.1-7.5,42.6-1.3c0.2,0.1,4.9,2,5,2.3c-0.1-0.8-0.5-1.9-1.1-3
              C508.7,65.7,500.3,60.7,490.4,58.6z'
									/>
								</g>
								<g>
									<path
										class='st0'
										d='M513.9,126.3c28.4-3.6,49-20.8,53.2-37.7c4.1-16.8-23.7-28.4-23.7-28.4C543.7,103,513.9,126.3,513.9,126.3z
              '
									/>
								</g>
								<g>
									<path
										class='st0'
										d='M400.3,160.6c27.7,21.4,82.9-18.9,82.9-18.9c-51.5,11.2-86.2-37.8-86.2-37.8S372.6,139.2,400.3,160.6z'
									/>
								</g>
								<g>
									<path
										class='st0'
										d='M594.9,137.1c-84.5-34.7-179,51.7-106.2,51C527,187.6,524.9,144.9,594.9,137.1z'
									/>
								</g>
								<g>
									<path
										class='st0'
										d='M472,93.3c7.1,5.8,43.9,15.8,44-1.8c0.5-15.2-31.2-13.2-40.6-12.6C495.1,80.8,491.1,98.4,472,93.3z'
									/>
								</g>
								<g>
									<path
										class='st0'
										d='M428.2,124c-23.5-36.5-0.2-74.6,42.6-74.2C436.8-13.4,352.7,70,428.2,124z'
									/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<path
									class='st2'
									d='M37.6,208.1H0.9l0-78.4l36.5-0.3c0.4-2.7,0.8-4.3,1.9-6.7c1.3-2.8,2.4-4.2,4.5-6.1c-1.8-1.8-2.7-3.1-3.8-5.6
            c-1.4-2.8-1.9-4.7-2.4-8V87.2l12.1-0.1c-4.3-3.4-9-8.1-10.9-16c-2-8.2-1.3-14.7,2.3-21.9c-3.7-10-3.3-19,1.3-28.3
            C49,7.9,69.2,7.3,71.9,10.4c8.6-6.2,14.5-8.3,24.4-8.6c10.2-0.3,16.4,1.6,25.4,7.8c3.1-1,24.5-2.9,31.3,10.9
            c4.3,8.9,5,16.9,2.3,26.8c3.4,8.4,3.8,15.4,1.3,24.4c-2.1,7.4-4.7,11.5-9.8,15.5h11.1v15.3c-0.2,3.1-0.6,5-1.7,7.8
            c-1.1,2.8-2,4.3-3.8,6.4c3.9,2.8,5.8,6.8,6,12.5c0.2,6.2-1.8,10.9-6,13.9c4.1,2.4,6.3,6.6,6.4,12.5c0.1,5.7-1.7,10-5.6,12.8
            c4.7,3.1,6.9,8.8,6.2,15.5c-0.8,7.6-5.4,12.9-11.3,13c2.5,4.5,3.2,8.4,2.6,13.9c-1.1,8.9-6.2,15-13.1,15.7H57
            c-6,0.4-10.1-1.7-14.4-7.2C39.9,215.7,38.6,213,37.6,208.1C37.6,208.1,38.6,213,37.6,208.1L37.6,208.1z M105.8,23.4
            c0.9-1.6,1.4-2.4,2.5-3.8c1.7-2.2,2.9-3.3,5-4.8c-8.8-6.8-16.5-7.3-26.2-2.8c-9.4,4.4-14.5,12-17.4,24.4c6-6.7,10.4-9.6,18-12.1
            C97.6,21.2,103.3,22.9,105.8,23.4L105.8,23.4z M93.4,36c0,2.4,2.1,4.3,4.7,4.3c2.6,0,4.7-1.9,4.7-4.3c0-2.4-2.1-4.3-4.7-4.3
            C95.5,31.7,93.4,33.6,93.4,36L93.4,36z M83.9,36.8C78,40.2,83.9,36.8,83.9,36.8c-5.9,3.5-8.9,10-8.7,18.4
            c0.3,10.8,7.8,15.6,15.7,18.6c7.9,3,14.2,1.7,20.9-4.5c-8.9-0.7-14.6-4.3-20.6-12.8C86.8,50.1,85,45.2,83.9,36.8L83.9,36.8z
            M97.8,49.6C101.2,54.1,97.8,49.6,97.8,49.6c3.3,4.6,5.8,6.7,10.3,9.1c4.1,2.2,6.8,2.8,11.2,2.8c2.3-5.4,2.4-10.3,0.2-15.9
            c-1.9-4.7-4.2-7.2-8.1-8.6c-0.8,3.9-1.9,6.2-4.3,8.8C104.2,49,101.6,50,97.8,49.6L97.8,49.6z M65.6,52.9
            c-3.3-5.8-4.4-10.4-4.3-17.6c0.1-6.8,1.3-11,4.5-16.4c-6.6-0.3-10.5,1.8-15.3,7c-4.1,4.5-4.3,12.2-1.2,17.9
            C53,50.3,58.1,53.4,65.6,52.9C65.6,52.9,58.1,53.4,65.6,52.9L65.6,52.9z M47.2,54.9c-0.1,3.4-0.8,19.7,15.3,29.9
            c9.6,6.1,22.6,1.9,27.3-2.5c-6.1-0.8-9.8-2.7-14.7-7.5c-4.2-4.1-6.4-7.4-8.7-13.6c-3.2,0.6-5.1,0.5-8.3-0.3
            C53.5,59.9,51,58.4,47.2,54.9L47.2,54.9z M111.6,85.3C117.6,85.9,111.6,85.3,111.6,85.3c6.1,0.6,17,4.4,24.7-0.6
            c7.2-4.7,11.8-9.7,12.5-19.6c1-12.9-8.8-28.3-22.9-27.7c4.6,8.1,5.5,16.2,2.7,25.7c-3,10.2-8.7,15.9-17.1,17.1V85.3L111.6,85.3z
            M114.5,27.7c7.1-1.2,11.4-0.8,18.2,1.8c5.6,2.1,8.6,4,13.4,8.3L149,36c-1.8-7.9-4.9-12.4-10.7-15.9
            C123.8,11.5,115.4,26.2,114.5,27.7L114.5,27.7z M148.8,96.3h-29.3c-1.8,0.1-2.8,0.6-4.3,1.9c-1.4,1.2-2,2.3-2.7,4.3v10.2
            l29.9-0.1c1.7-0.1,2.8-0.6,4.1-2c1.4-1.6,2.1-2.5,2.3-5.5C148.8,104.7,148.9,96.3,148.8,96.3L148.8,96.3z M102.3,112.6l-0.1-26.6
            c-2,0.8-3.2,1.5-5,2.8c-1.5,1-2.3,1.7-3.7,3l0,17.1c0.2,1.1,0.5,1.7,1.2,2.5c0.5,0.6,0.9,0.9,1.6,1.1H102.3L102.3,112.6z
            M83.7,112.6c0,0,0-8.8,0-8.8c-0.5-3-0.9-4-2.3-5.5c-1.3-1.4-2.3-1.9-4-2l-29.9,0v10.1c0.7,2,1.4,3,2.7,4.3
            c1.4,1.3,2.5,1.8,4.3,2H83.7L83.7,112.6z M119.3,122.4c0.3,0.2,0.9,0.7,1.5,2c0.5,1.2,0.7,2,0.6,3.3l0.1,10.8l20.9,0
            c3.5,0,6.4-4,6.2-8.6c-0.2-4.4-3.1-7.8-6.5-7.6H119.3L119.3,122.4z M122.1,148.5l0,15.6h21.6c3-0.5,5.1-3.3,5.3-7.2
            c0.2-4.5-2.7-8.5-6.2-8.3H122.1L122.1,148.5z M122.5,173.9L101,174c-3.5-0.2-6.6,3.4-6.6,8c-0.1,4.7,3.2,8.7,6.8,8.3h42.3
            c3.4-0.1,6-3.4,6.2-7.8c0.2-4.7-2.8-8.7-6.4-8.6H122.5L122.5,173.9z M112.2,164.1l0.1-33.6c-0.2-2.7-0.9-4.4-2.6-6.1
            c-1.5-1.6-2.8-2.1-4.7-2H52.6c-1.7,0.2-2.8,1-3.9,2.7c-1.2,1.8-1.6,3.5-1.4,5.8l0.1,23.7c0.1,2.4,0.6,4,1.8,5.8
            c1.6,2.5,3.3,3.5,5.8,3.5l21.9,0c2.1,0.1,3.4-0.5,5-2.2c1.7-1.8,2.5-3.5,2.8-6.4v-16.6h9l-0.1,15.7c0.4,3.1,1.2,5,3.1,7
            c1.9,2,3.5,2.7,5.9,2.6L112.2,164.1L112.2,164.1z M86.6,216.5C84.8,213.2,86.6,216.5,86.6,216.5c-1.8-3.3-2.3-5.9-2.1-10
            c0.4-5.5,2.2-9.2,6-11.9c-3.9-3.2-5.8-7.3-6-13.3c-0.1-4.5,0.6-7.5,2.8-11c-1.2,0.8-1.9,1.2-3.2,1.8c-2.5,1.2-4,1.6-6.6,1.9H54.1
            c-5.6-0.2-9.4-2.8-12.8-8.5c-2.4-4-3.3-7.1-3.5-12.2l0-14.8l-27.7,0.1l0,59.7l36.3,0v7c0.6,3.3,1.5,5.1,3.5,7.4
            c2.4,2.8,4.5,3.9,7.7,3.9H86.6L86.6,216.5z M118.2,200.3H101c-3.4,0-6.2,3.5-6.2,8c0,4.3,2.4,7.8,5.7,8.2h35.1
            c3-0.7,5-3.7,5.1-7.7c0.1-4.3-2.1-7.8-5.3-8.5H118.2z'
								/>
							</g>
						</g>
					</g>
				</svg>
			</div>
		</div>
	)
}

export default Loading
