import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../Image/Image'

import style from './CardButtonHorizontal.module.scss'

function CardButtonHorizontal({ title, src, pictureClass = '', link = '#' }) {
	return (
		<Link to={link} className={style.cardButtonHorizontal}>
			<h4>{title}</h4>
			<Image src={src} alt='' layout='fill' className={style.picture + ' ' + pictureClass} />
		</Link>
	)
}

export default CardButtonHorizontal
