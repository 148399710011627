import React from 'react'
import NewsContainer from '../NewsContainer/NewsContainer'

import styles from './SectionNews.module.scss'

function SectionNews({news}) {
  return (
    <section className={styles.newsSection}>
			<NewsContainer news={news} />
    </section>
  )
}

export default SectionNews