import Image from '../Image/Image'
import { Link } from 'react-router-dom'
import React from 'react'
import { getDateFormatted } from '../../lib/formatters'
import sanitize from '../../lib/sanitize'

import styles from './NewsSingleWide.module.scss'

function NewsSingleWide({ news = {}, onImageLoad = () => null }, ref) {
	const { src, alt = '', title, category, date, description, href } = news
	const formattedDate = getDateFormatted(date)

	return (
		<Link to={href ? href : '#'} className={styles.featuredCard} ref={ref}>
			<Image src={src} alt={alt} layout="responsive" onLoad={onImageLoad} className={styles.cardImg}/> 
			<div className={styles.cardText}>
				<header>
					<h3 className={styles.title}>{title}</h3>
					<div className={styles.subTitle}>
						<time dateTime={date}>{formattedDate}</time>
					</div>
				</header>
				<div className={styles.description} dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
			</div>
		</Link>
	)
}

export default React.forwardRef(NewsSingleWide)
