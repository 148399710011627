import React from 'react'
import styles from './PageDefaultContainer.module.scss'

function PageDefaultContainer({content}) {
	return (
		<div className={styles.content} dangerouslySetInnerHTML={{ __html: content }}/>
	)
}

export default PageDefaultContainer
