import React from 'react'
import Hero from '../components/Hero/Hero'
import SectionComoParticipar from '../components/SectionComoParticipar/SectionComoParticipar'
import DefaultLayout from '../layout/DefaultLayout/DefaultLayout'

function ComoParticiparPage() {
  return (
    <DefaultLayout>
      <Hero img="/assets/img/participa.jpg">
        <h2 className="heroHeadings">¿Cómo participar?</h2>
      </Hero>
      <SectionComoParticipar />
    </DefaultLayout>
  )
}

export default ComoParticiparPage