import React from 'react'
import { ScrollRestoration } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

import styles from './DefaultLayout.module.scss'
import Button from '../../components/Button/Button'
import { whatsapp } from '../../constants/redes'
import { FaWhatsapp } from 'react-icons/fa'

function DefaultLayout({ children, padding = 'base' }) {
	let getKey = React.useCallback((location, matches) => {
		let match = matches.find((m) => m.handle?.scrollMode)
		if (match?.handle?.scrollMode === 'pathname') {
			return location.pathname
		}

		return location.key
	}, [])
	return (
		<React.Fragment>
			<Header />
			<main className={styles[padding]}>{children}</main>
			<Footer />
			<ScrollRestoration getKey={getKey} />
			<Button theme='icon' href={whatsapp} external className={styles.whatsapp}>
				<FaWhatsapp />
			</Button>
		</React.Fragment>
	)
}

export default DefaultLayout
