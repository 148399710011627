import React from 'react'
import Button from '../Button/Button'
import Container from '../Container/Container'
import Hero from '../Hero/Hero'

import styles from './SectionHero.module.scss'

function SectionHero() {
  return (
    <Hero video="/assets/videos/hero.mp4">
				<Container className={styles.heroContainer}>
          <h2 className='heroHeadings'>¡SUMATE!</h2>
          <p className='heroText'>
            Somos un colectivo que marcha, un grupo en movimiento que sueña y actúa. Somos un partido
            político. Somos un lugar al que queremos ir, un tiempo vivido y por vivir, somos las cosas
            que faltan cambiar.
          </p>
          <Button href="/como-participar">¡Conocé Más!</Button>
        </Container>
			</Hero>
  )
}

export default SectionHero