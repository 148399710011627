import React from 'react'
import DefaultSectionContainer from '../../components/DefaultSectionContainer/DefaultSectionContainer'
import Loading from '../../components/Loading/Loading'
import PageDefaultContainer from '../../components/PageDefaultContainer/PageDefaultContainer'

import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout'
import fetcher from '../../lib/fetcher'

function EstatutoJSPage() {
	const [content, setContent] = React.useState('')
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		async function getPage() {
			try {
				let response = await fetcher('/pages/12499')
				setContent(response.content.rendered)
				setLoading(false)
			} catch (error) {
				setLoading(false)
			}
		}

		getPage()
	}, [])

	if (loading) {
		return <Loading />
	}

	return (
		<DefaultLayout>
			<DefaultSectionContainer>
				<PageDefaultContainer content={content} />
			</DefaultSectionContainer>
		</DefaultLayout>
	)
}

export default EstatutoJSPage
