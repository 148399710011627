import React from 'react'

import fetcher from '../lib/fetcher'
import NewsletterSection from '../components/NewsletterSection/NewsletterSection'
import QueDecimosSection from '../components/QueDecimosSection/QueDecimosSection'
import SectionHero from '../components/SectionHero/SectionHero'
import SectionNews from '../components/SectionNews/SectionNews'
import SocialistasHaciendoSection from '../components/SocialistasHaciendoSection/SocialistasHaciendoSection'
import DefaultLayout from '../layout/DefaultLayout/DefaultLayout'
import useSocialistasHaciendo from '../hooks/useSocialistasHaciendo'
import Loading from '../components/Loading/Loading'
import SitiosDeInteres from '../components/SitiosDeInteres/SitiosDeInteres'

export default function HomePage() {
	const [news, setNews] = React.useState([])
	const [cita, setCita] = React.useState({})
	const { socialistasHaciendo, loading } = useSocialistasHaciendo()

	React.useEffect(() => {
		;(async function getPosts() {
			try {
				let response = await fetcher('/posts?per_page=4&categories=8')
				const imgs = await Promise.all(
					response.map((post) => {
						return fetcher(`/media/${post.featured_media}`)
					}),
				)
				response = response.map((post) => {
					return {
						...post,
						img: imgs.find((img) => img.id === post.featured_media) || {},
					}
				})
				const arrPosts = response.map((post, index) => {
					let { title, slug, date, id, excerpt, img } = post

					return {
						title: title.rendered,
						slug,
						date,
						description: excerpt.rendered,
						src: img?.media_details?.sizes?.medium?.source_url || '',
						alt: '',
						key: id,
						category: 'PS Federal',
						featured: index === 0,
						href: '/noticias/' + id + '/' + slug,
					}
				})
				setNews(arrPosts)
			} catch (error) {
				console.error(error)
				return []
			}
		})()
	}, [])

	React.useEffect(() => {
		;(async function getCita() {
			try {
				let response = await fetcher('/que-decimos?per_page=1')

				if (Array.isArray(response) && response.length > 0) {
					let autor = await fetcher('/socialistas/' + response[0].acf.autor[0])
					let autorImg = await fetcher('/media/' + autor.featured_media)
					setCita({
						title: '“' + response[0].title.rendered + '”',
						href: response[0].acf.fuente,
						src: autorImg.media_details.sizes.thumbnail.source_url,
						author: autor.title.rendered,
						key: response[0].id,
					})
				}
			} catch (error) {
				console.error(error)
			}
		})()
	}, [])

	if (loading) {
		return <Loading />
	}

	return (
		<DefaultLayout>
			<SectionHero />
			<SectionNews news={news} />
			{cita && <QueDecimosSection cita={cita} />}
			<SocialistasHaciendoSection socialistasHaciendo={socialistasHaciendo} />
			<NewsletterSection />
			<SitiosDeInteres />
		</DefaultLayout>
	)
}
