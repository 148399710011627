import React from 'react'
import AllNewsSection from '../../components/AllNewsSection/AllNewsSection'
import Loading from '../../components/Loading/Loading'
import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout'
import fetcher from '../../lib/fetcher'

function NoticiasPage() {
	const [news, setNews] = React.useState([])
	const [page, setPage] = React.useState(1);
	const [loading, setLoading] = React.useState(true)
	const [loadingMore, setLoadingMore] = React.useState(false)
	const [morePosts, setMorePosts] = React.useState(true);

	const handleScroll = React.useCallback(() => {
		if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
		setPage(page + 1);
	}, [page]);

	React.useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};	
	}, [handleScroll]);

	React.useEffect(() => {
		async function getMorePosts() {
			if (morePosts === false) {
				return;
			}
			page === 1 ? setLoading(true) : setLoadingMore(true)
			try{
				let newPosts = await fetcher(`/posts?page=${page}&per_page=${page === 1 ? 5 : 4}`)
				if(newPosts.code) {
					if(newPosts.code === 'rest_post_invalid_page_number') {
						setMorePosts(false)
						setLoading(false);
						setLoadingMore(false)
						return
					}
				}
				let media = await Promise.all(newPosts.map((post) => {
					return fetcher(`/media/${post.featured_media}`)
				}))
				newPosts = newPosts.map((post, index) => {
					return {
						...post,
						img: media.find(img => img.id === post.featured_media) || {}
					}
				})
				const arrPosts = newPosts.map((post, index) => {
					let { title, slug, date, id, excerpt, img } = post

					return {
						title: title.rendered,
						slug,
						date,
						description: excerpt.rendered,
						src: img?.media_details?.sizes?.medium?.source_url || '',
						alt: '',
						key: id,
						category: 'PS Federal',
						featured: index === 0,
						href: '/noticias/'+id+'/'+slug,
					}
				})
				setNews((oldState) => {
					let newState = [...oldState]
					arrPosts.forEach(post => {
						if (!newState.find(p => p.key === post.key)) {
							newState.push(post)
						}
					})
					return newState;
				});
			} catch (error) {
				console.error(error);
			}
			page === 1 ? setLoading(false) : setLoadingMore(false)
		}
		getMorePosts();
	}, [morePosts, page]);

	if (loading) {
		return <Loading />
	}
	return (
		<DefaultLayout padding="x2">
			<AllNewsSection news={news} />
			{loadingMore ? <p>Loading more...</p> : null}
		</DefaultLayout>
	)
}

export default NoticiasPage
