
import React from 'react'

const useNav = (initialState) => {
  const [navVisible, setNavVisible] = React.useState(initialState);

  const toggleNav = () => {
		setNavVisible((prev) => !prev)
	}

  React.useEffect(() => {
    document.body.style.overflow = navVisible ? "hidden" : "inherit";
	}, [navVisible])

  return [toggleNav, navVisible]
}

export default useNav