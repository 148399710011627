import React from 'react'
import { Link } from 'react-router-dom'

import styles from './SectionHeading.module.scss'

function SectionHeading({ children, back, className = '' }) {
	return (
		<div className={styles.headingBox}>
			{back && (
				<Link to={back} className={styles.back}>
					<svg
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z'
							fill='currentColor'
						/>
					</svg>
					Volver
				</Link>
			)}
			<h2 className={styles.heading + ' ' + className}>{children}</h2>
		</div>
	)
}

export default SectionHeading
