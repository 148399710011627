import React from 'react'
import NewsSingle from '../NewsSingle/NewsSingle'


import styles from './NewsContainerSimple.module.scss'

function NewsContainerSimple({ news }) {

	return (
		<div className={styles.newsRow}>
			{news.map((singleNews, index) => (
				<NewsSingle
					key={singleNews.key}
					news={singleNews}
				/>
			))}
		</div>
	)
}

export default NewsContainerSimple
