import React from 'react'
import fetcher from '../lib/fetcher'

const useLegisladores = (initialState = []) => {
	const [legisladores, setLegisladores] = React.useState(initialState)
	const [loading, setLoading] = React.useState(true)
	React.useEffect(() => {
		const getData = async () => {
			try {
				let response = await fetcher('/socialistas?per_page=100')

				response = response.filter(socialista => socialista.acf.esLegislador === true)
				
				let legisladoresImgs = await Promise.all(
					response.map((legislador) => {
						return fetcher(`/media/${legislador.featured_media}`)
					}),
				)
        
				let allLegisladores = response.map((legislador) => {
					let img = legisladoresImgs.find((img) => img.id === legislador.featured_media) || {}
					return {
						nombre: legislador.title.rendered,
						cargo: legislador.acf.cargo,
						src: img.source_url,
						email: legislador.acf.email,
						redes: Object.keys(legislador.acf.redes_sociales)
							.filter((key) => legislador.acf.redes_sociales[key] !== '')
							.map((key) => {
								return {
									name: key,
									href: legislador.acf.redes_sociales[key],
								}
							}),
					}
				})

				setLegisladores(allLegisladores)
				setLoading(false)
			} catch (error) {
				console.error(error)
				setLoading(false)
			}
		}

		getData()

	}, [])

	return {legisladores, loading}
}

export default useLegisladores
