import React from 'react'
import Button from '../Button/Button'
import Image from '../Image/Image'

import styles from './QueDecimosCard.module.scss'

function QueDecimosCard({cita, xs}) {
	const {title, href, src, author} = cita
	const openLink = () => window.open(href, "_blank")
	return (
		<div className={styles.queDecimosCard + ' ' + styles[xs]}>
			<div className={styles.queDecimosCardCita}>
				<h4 className={styles.queDecimosAuthorWeb}>{author}</h4>
				<h3 className={styles.queDecimosCardHeader}>{title}</h3>
				<Button theme="defaultAlt" className={styles.queDecimosBtn} handleClick={openLink}>Leer Más</Button>
			</div>
			<div className={styles.queDecimosImg}>
				<Image className={styles.queDecimosImg} src={src} alt={author} layout="responsive" width={350} height={350} />
			</div>
			<h4 className={styles.queDecimosAuthor}>{author}</h4>
		</div>
	)
}

export default QueDecimosCard
